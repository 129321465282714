<template>
  <app-header />
  <div id="wrapper">
    <app-sidebar />
    <div id="content-wrapper">
      <div class="container-fluid pb-0">
        <Suspense>
          <template #default>
            <app-search :search_id="search_id" />
          </template>
          <template #fallback>
            <loading />
          </template>
        </Suspense>
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
import AppFooter from "../../components/Include/AppFooter.vue";
import AppHeader from "../../components/Include/AppHeader.vue";
import AppSidebar from "../../components/Include/AppSidebar.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Loading from "../../components/Include/Loading.vue";
const AppSearch = defineAsyncComponent(() =>
  import("../../components/Pages/AppSearch.vue")
);
export default {
    props: {
    search_id: String
  },
   name: 'Searchs',
   components: {
    AppHeader,
    AppSidebar,
    AppSearch,
    AppFooter,
    Loading,
    Suspense,
  },
}
</script>

<style>

</style>